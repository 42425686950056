import React, { useState, useEffect } from "react"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Content from "../components/content/content"
import { Loading } from "../components/loading"
import { Form, Input, FormGroup, Button, Label, Col, Row } from "reactstrap"
import { fetch_post } from "../utils/fetch"
import { toast } from "react-toastify"
import "../styles/contact.scss"
import { useAuth0 } from "@auth0/auth0-react"
import { checkPassword } from "../utils/common"

export default function ContactPage() {
  const { isLoading, isAuthenticated, user } = useAuth0()
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("general")
  const [subjectDescription, setSubjectDescription] = useState("general")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [inputCode, setInputCode] = useState("")
  const [markerCode, setMarkerCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [formInvalid, setFormInvalid] = useState(true)
  const [inputCodeInvalid, setInputCodeInvalid] = useState("")
  const [showModal, setShowModal] = useState(false)

  var subjectLists = [
    {
      id: "general",
      value: "General Questions",
      description: "General CodeLinaro questions or contact about CodeLinaro",
    },
    {
      id: "report",
      value: "Report abusive conduct or behaviour",
      description:
        "Report issues with CodeLinaro Community Guidelines or CodeLinaro Acceptable Use Policy. ",
    },
    {
      id: "personal",
      value: "Personal/Private Information requests.",
      description:
        "Requests for private information removal.  See <a target='_blank' href='/faq/209813505/'>CodeLinaro Private Information Removal Policy.</a> ",
    },
    {
      id: "account",
      value: "Report suspected breach of CodeLinaro or your account.",
      description:
        "Notify CodeLinaro if you become aware of any unauthorized use of, or access to, our Service through your Account, including any unauthorized use of your password or Account.",
    },
  ]

  const handle_subject_change = val => {
    setSubject(val)
    let searchRes = subjectLists.filter(function (item) {
      return val === item.id
    })
    setSubjectDescription(searchRes[0].description)
  }
  //Verification Code
  const createCode = () => {
    var canvasBtn = document.getElementById("mycanvas")
    var context = canvasBtn.getContext("2d")
    var codeNum = ""
    var codeLength = 4
    var random = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ]
    canvasBtn.width = 70
    canvasBtn.height = 36
    for (var i = 0; i < codeLength; i++) {
      var index = Math.floor(Math.random() * 36)
      codeNum += random[index]
    }
    const result = codeNum.toLowerCase()
    setMarkerCode(result)
    context.font = "20px Lato"
    context.fillStyle = "#495057"
    context.textAlign = "center"
    context.fillText(result, 35, 25)

    //Draw interference lines
    for (var l = 0; l <= 5; l++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      context.moveTo(Math.random() * 70, Math.random() * 36)
      context.lineTo(Math.random() * 70, Math.random() * 36)
      context.stroke()
    }

    //Draw interference points
    for (var p = 0; p <= 30; p++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      var x = Math.random() * 70
      var y = Math.random() * 36
      context.moveTo(x, y)
      context.lineTo(x + 1, y + 1)
      context.stroke()
    }
  }

  // Random color
  const randomColor = () => {
    var r = Math.floor(Math.random() * 256)
    var g = Math.floor(Math.random() * 256)
    var b = Math.floor(Math.random() * 256)
    return "rgb(" + r + "," + g + "," + b + ")"
  }

  useEffect(() => {
    if (!isLoading) {
      if (markerCode === "") {
        createCode()
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, markerCode])

  useEffect(() => {
    // Check whether it can be committed
    if (inputCode.length <= 0) {
      setInputCodeInvalid("success")
    } else if (String(inputCode) !== String(markerCode)) {
      setInputCodeInvalid("error")
    } else {
      setInputCodeInvalid("success")
    }

    if (
      String(inputCode) === String(markerCode) &&
      subject !== "" &&
      name !== "" &&
      email !== "" &&
      message !== ""
    ) {
      setFormInvalid(false)
    } else {
      setFormInvalid(true)
    }
  }, [subject, name, email, message, inputCode, markerCode])

  useEffect(() => {
    handle_subject_change(subject)
  }, [])

  if (isLoading) {
    return <Loading />
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (checkPassword(isAuthenticated, user)) return
    setLoading(true)
    fetch_post(
      `ui/contact`,
      {
        subject,
        name,
        email,
        message,
      },
      null
    )
      .then(() => {
        setShowModal(true)
      })
      .catch(error => {
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCloseModal = () => {
    setShowModal(null)
    setSubject("general")
    setName("")
    setEmail("")
    setMessage("")
    setInputCode("")
    setMarkerCode("")
  }

  return (
    <Layout pageTitle="Contact Us">
      <Header backgroundImage="/dashborad_header.png" className="contentPage" contentPage={true}>
        <h1 className="display-4 font-weight-bold">Contact CodeLinaro</h1>
      </Header>
      <Content>
        {showModal ? (
          <div style={{ fontSize: "16px" }}>
            <p>Thank you for reaching out!</p>
            <p>A member of the CodeLinaro team will get in touch with you soon.</p>
            <Button className="btnBgColor" onClick={handleCloseModal}>
              Back
            </Button>
          </div>
        ) : (
          <>
            <div>
              <p>
                Please fill out the form below or email us directly at{" "}
                <a href="mailto:contact@codelinaro.org?subject=Contact%20Us%20|%20CodeLinaro">
                  contact@codelinaro.org
                </a>
                . We look forward to hearing from you!
              </p>
              <p>
                If you have a technical support query relating to CodeLinaro, please email{" "}
                <a href="mailto:support@codelinaro.org?subject=Contact%20Us%20|%20CodeLinaro">
                  support@codelinaro.org
                </a>
                .
              </p>
            </div>
            <Form>
              <FormGroup row>
                <Label for="name" sm={1}>
                  Name:
                </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={event => setName(event.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="email" sm={1}>
                  Email:
                </Label>
                <Col sm={5}>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="name" sm={1}>
                  Subject:
                </Label>
                <Col sm={5}>
                  <Input
                    type="select"
                    name="subject"
                    id="subject"
                    value={subject}
                    // onChange={event => setSubject(event.target.value)}
                    onChange={event => handle_subject_change(event.target.value)}
                  >
                    {/* <option value="general">General Questions</option>
                    <option value="report">Report abusive conduct or behaviour</option>
                    <option value="personal">Personal/Private Information requests</option>
                    <option value="account">
                      Report suspected breach of CodeLinaro or your account.
                    </option> */}

                    {subjectLists.map((p, index) => (
                      <option value={p.id}>{p.value}</option>
                    ))}
                  </Input>
                  <span className="badge bg-info text-white">Info</span>
                  &nbsp;
                  <small dangerouslySetInnerHTML={{ __html: subjectDescription }}></small>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="message" sm={1}>
                  Message:
                </Label>
                <Col sm={5}>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                  />
                  <span className="badge bg-info text-white">Info</span>
                  &nbsp;
                  <small>Please provide as much detail for your request as possible.  Provide URLs, Repository, File names, error messages, etc.. to help us identify your issue.</small>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="captcha" sm={1}>
                  Captcha:
                </Label>
                <Col sm={4}>
                  <Input
                    type="text"
                    name="inputCode"
                    id="inputCode"
                    value={inputCode}
                    onChange={event => setInputCode(event.target.value)}
                  />
                </Col>
                <Col sm={1}>
                  <div className="captchaBtn">
                    <canvas id="mycanvas" onClick={createCode}></canvas>
                  </div>
                </Col>
              </FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col>
                  <p style={{ fontSize: 12, color: "red" }}>
                    {inputCodeInvalid === "success" ? "" : "Wrong input"}
                  </p>
                  <Button
                    className="mr-2 btnBgColor"
                    color={"primary"}
                    onClick={handleSubmit}
                    disabled={formInvalid}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      >
                        <span className="sr-only">Sending...</span>
                      </span>
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Content>
    </Layout>
  )
}
